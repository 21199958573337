<template>
  <div id="accountApply">
    <header-nav :content="`${$t('pages_router_003')} / ${ $t('pages_router_005')}`" />
    <div class="common-card-body">
      <a-steps :current="0" size="small" class="apply-step">
        <a-step :title="$t('pages_receive_016')" />
        <a-step :title="$t('pages_receive_017')" />
        <a-step :title="$t('pages_receive_018')" />
      </a-steps>
      <div class="apply-desc">{{ $t('pages_receive_019') }}</div>
      <div class="apply-business">
        <a-tabs v-model:activeKey="businessType" @change="loadBusinessList">
          <a-tab-pane v-if="showTap.includes(1)" key="00">
            <template #tab>
              <span><shopping-filled />{{ $t('pages_receive_042') }}</span>
            </template>
          </a-tab-pane>
          <a-tab-pane v-if="showTap.includes(2)" key="10">
            <template #tab>
              <span><alert-filled />{{ $t('pages_receive_043') }}</span>
            </template>
          </a-tab-pane>
          <a-tab-pane v-if="showTap.includes(3)" key="20">
            <template #tab>
              <span><dribbble-circle-filled />{{ $t('pages_receive_044') }}</span>
            </template>
          </a-tab-pane>
        </a-tabs>
        <div class="business-list">
          <div
            v-for="(item, index) in businessOpts"
            :key="index"
            class="business"
          >
            <div class="flex-align content" @click="linkToApply(item)">
              <img v-if="item.imgUrl" :src="item.imgUrl" :style="{width: `${item.width}px` || ''}">
              <span v-if="['AMAZON', 'EBAY'].includes(item.pageName) || ['20', '30'].includes(item.value)">{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { _receive } from '@/api/index'
import i18n from '@/locale/index'
import HeaderNav from '@/components/header-nav'
import businessList from '@/common/business'
export default {
  name: 'ApplyFill',
  components: {
    'header-nav': HeaderNav
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      businessType: '00',
      businessOpts: [],
      showTap: [1, 2, 3]
    })
    const linkToApply = (item) => {
      const query = {
        businessType: item.businessType,
        businessChildType: item.value,
        pageName: item.pageName
      }
      if (!['20', '30'].includes(item.value)) {
        let queryData = query
        if (route.query.p && route.query.p === 'va') {
          queryData = Object.assign(queryData, { p: 'va' })
        }
        router.push({ path: '/receive/e_commerce', query: queryData })
      } else {
        let queryData = { businessType: item.value }
        if (route.query.p && route.query.p === 'va') {
          queryData = Object.assign(queryData, { p: 'va', c: route.query.c })
        }
        router.push({ path: '/receive/t_trade', query: queryData })
      }
    }
    const loadBusinessList = async () => {
      if (route.query.p && route.query.c) {
        const productType = route.query.c
        if (productType === 'B2B1') {
          state.businessType = '20'
          state.showTap = [3]
          if (route.query.n === 'o') {
            linkToApply({ name: i18n.global.t('pages_receive_045'), value: '20' })
            return false
          }
        } else if (productType === 'B2B2') {
          state.businessType = '20'
          state.showTap = [3]
          if (route.query.n === 'o') {
            linkToApply({ name: i18n.global.t('pages_receive_046'), value: '30' })
            return false
          }
        } else if (productType === 'B2C1' || productType === 'B2C2') {
          state.showTap = [1, 2]
        }
      } else {
        state.showTap = [1, 2, 3]
      }
      if (state.businessType === '20') {
        state.businessOpts = [
          { name: i18n.global.t('pages_receive_045'), value: '20' },
          { name: i18n.global.t('pages_receive_046'), value: '30' }
        ]
      } else {
        const res = await _receive.getBusinessChildType({ businessType: state.businessType })
        if (res.data.succ) {
          const businessOpts = []
          res.data.data.map(item => {
            const business = businessList.find(business => business.value === item)
            if (business) businessOpts.push(business)
          })
          state.businessOpts = businessOpts
        }
      }
    }
    loadBusinessList()
    return {
      linkToApply,
      loadBusinessList,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less">
#accountApply {
  .common-card-body {
    padding: 18px 36px;
    .apply-desc {
      font-size: 12px;
      color: #666;
      margin-top: 18px;
      text-align: left;
    }
    .apply-business {
      margin-top: 16px;
      .ant-tabs-ink-bar {
        background: @main-color;
      }
      .ant-tabs-tab {
        .anticon {
          margin-right: 3px;
        }
      }
      .ant-tabs-tab:hover {
        color: @main-color !important;
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: @main-color !important;
        }
      }
      .ant-tabs-tab + .ant-tabs-tab {
        margin-left: 64px;
      }
      .business-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;
        .business {
          flex: 0 0 25%;
          padding: 0 32px 32px 0;
          overflow: hidden;
          .content {
            border: 1px solid #DFDFDF;
            border-radius: 30px;
            font-size: 12px;
            justify-content: center;
            overflow: hidden;
            height: 34px;
            transition: all .3s cubic-bezier(.645, .045, .355, 1);
            cursor: pointer;
            img {
              width: 75px;
            }
          }
          .content:hover {
            border: 1px solid @main-color;
            color: @main-color;
          }
        }
      }
    }
  }
}
</style>
